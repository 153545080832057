/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import * as sanitizeHtml from 'sanitize-html';

import SbResolve from '../../utils/SbResolve';

// ====

const Richtext = ({ content, mode }) => {
  const dirty = SbResolve(content);
  const cleanNormal = sanitizeHtml(dirty, {
    allowedTags: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'b',
      'i',
      'em',
      'strong',
      'a',
      'p',
      'img',
      'ul',
      'li',
    ],
    allowedAttributes: {
      a: ['href', 'target', 'alt'],
      img: ['src', 'width', 'height'],
    },
    allowedIframeHostnames: ['www.youtube.com'],
  });

  const cleanSimple = sanitizeHtml(dirty, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ul', 'li'],
    allowedAttributes: {
      a: ['href'],
    },
  });

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: mode === 'simple' ? cleanSimple : cleanNormal,
      }}
    />
  );

  //return <div dangerouslySetInnerHTML={{ __html: clean }} />;
};

export default Richtext;

// ====

Richtext.propTypes = {
  mode: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

Richtext.defaultProps = {
  mode: 'normal',
};
