import React from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import Richtext from '../Richtext/Richtext';
import Link from '../Global/Link';

// ====

const CookiesNotice = (props) => {
  const { message, mode } = props;

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="day1games-gdpr-google-analytics"
        disableStyles
        acceptOnScroll
        ButtonComponent={({ children, ...props }) => (
          <Link
            rel="nofollow noopener noreferrer"
            id="cookieBarButton"
            button
            buttonReal
            {...props}
          >
            {children}
          </Link>
        )}
        containerClasses="fixed-bottom brand-bg-white brand-text-blue p-3 align-items-center row brand-cookiebar border-top"
        contentClasses="col-12 col-md-8 mb-3 mb-md-0 mt-3 text-center text-md-left"
        buttonWrapperClasses="col-12 col-md-4 text-center text-md-right"
        expires={150}
      >
        <Richtext content={message} />
      </CookieConsent>
    </>
  );
};

export default CookiesNotice;

CookiesNotice.propTypes = {
  message: PropTypes.string,
  mode: PropTypes.string,
};

CookiesNotice.defaultProps = {
  message: 'By using this website you consent to our use of cookies',
  mode: 'normal',
};
