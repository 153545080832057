import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

// ====

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  className,
  button,
  font,
  stretched,
  invert,
  newWindow,
  ...other
}) => {
  let styles = '';
  if (button) {
    styles = 'btn brand-button brand-font brand-font-uppercase ';
    if (invert) {
      styles += ' brand-button-invert ';
    }
    font = true;
  }
  if (font) {
    styles += 'brand-font-caps ';
  }
  if (stretched) {
    styles += 'stretched-link ';
  }
  // Any internal link (intended for Gatsby)
  // will start with exactly one slash
  // anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  if (button === 'real') {
    return (
      <button
        type="submit"
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${className && `${className} `}${styles && styles}`}
        {...other}
      >
        {children}
      </button>
    );
  }
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${className && `${className} `}${styles && styles}`}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={to}
      target={newWindow ? '_blank' : '_self'}
      className={`${className && `${className} `}${styles && styles}`}
      {...other}
    >
      {children}
    </a>
  );
};

export default Link;

// ====

Link.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  className: PropTypes.string,
  button: PropTypes.bool,
  font: PropTypes.string,
  stretched: PropTypes.bool,
  invert: PropTypes.bool,
  newWindow: PropTypes.bool,
};

Link.defaultProps = {
  to: null,
  activeClassName: null,
  partiallyActive: null,
  className: '',
  button: false,
  font: null,
  stretched: false,
  invert: false,
  newWindow: false,
};
