import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
//import gsap from 'gsap';

import PropTypes, { node } from 'prop-types';

//import 'modern-normalize/modern-normalize.css';
import '../../scss/bootstrap.scss';

// ====

function toggleMenu(status) {}

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);
  useEffect(() => {
    toggleMenu(menuOpen);
  });
  const siteData = useStaticQuery(graphql`
    {
      storyblokEntry(slug: { eq: "settings" }) {
        content
      }
    }
  `);
  let siteDoc = JSON.parse(siteData.storyblokEntry.content);

  // Site has the pages in it!
  return (
    <header>
      <nav role="navigation" className="text-right brand-text-white fixed-top">
        {siteDoc.navigation && (
          <div
            className={`brand-menu brand-bg-black-alpha ${
              menuOpen === true ? `brand-menu-open` : ``
            }`}
            id="brandMenu"
            ref={navRef}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="brand-menu-items brand-font brand-text-white">
              {siteDoc.navigation.map((node) => {
                return (
                  <Link
                    to={`/#${node.link.cached_url}`}
                    className="nav-item nav-link"
                    activeClassName="active"
                    partiallyActive={node.link.cached_url === '' ? false : true}
                    onClick={() => setMenuOpen(!menuOpen)}
                    key={node._uid}
                  >
                    {node.name}
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        <button
          className={`brand-menu-trigger hamburger hamburger--spin ${
            menuOpen === true ? `is-active` : ``
          }`}
          type="button"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </nav>
    </header>
  );
};

export default Header;

// ====

Header.propTypes = {};

Header.defaultProps = {};
