module.exports = {
  siteMetadata: {
    title: 'Day1 Games',
    description: 'Website for Day1 Games',
    siteUrl: 'https://day1games.com',
    author: 'Richy Pears',
    baseUrl: 'https://day1games.com',
    postAuthor: 'Day1 Games',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'uNrHXpN1L6RDgoZjJg2PFgtt',
        resolveLinks: true,
        homeSlug: 'home',
        version: process.env.NODE_ENV === `production` ? `published` : `draft`,
      },
    },
    {
      resolve: 'gatsby-plugin-i18n',
      options: {
        langKeyForNull: 'uk',
        langKeyDefault: 'uk',
        useLangKeyLayout: false,
        prefixDefault: false,
      },
    },
    {
      resolve: `gatsby-plugin-gdpr-cookies`,
      options: {
        googleAnalytics: {
          trackingId: '', // leave empty if you want to disable the tracker
          cookieName: 'day1games-gdpr-google-analytics', // default
          anonymize: true, // default
          allowAdFeatures: false, // default
        },
        googleTagManager: {
          trackingId: '', // leave empty if you want to disable the tracker
          cookieName: 'day1games-gdpr-google-tagmanager', // default
          dataLayerName: 'dataLayer', // default
        },
        facebookPixel: {
          pixelId: '', // leave empty if you want to disable the tracker
          cookieName: 'day1games-gdpr-facebook-pixel', // default
        },
        // defines the environments where the tracking should be available  - default is ["production"]
        environments: ['production', 'development'],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Day1 Games',
        short_name: 'Day1 Games',
        description: 'Day1 Games',
        homepage_url: 'https://day1games.com',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#14263a',
        display: 'standalone',
        icon: 'src/icons/icon.svg',
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [
          `Dosis\:400`, // you can also specify font weights and styles
        ],
        display: 'swap',
      },
    },
    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        headers: {
          '/*': [
            'Content-Security-Policy: frame-ancestors https://app.storyblok.com/',
          ],
        },
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-remove-serviceworker',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-transformer-sharp',
  ],
};
