import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Richtext from '../Richtext/Richtext';
import CookiesNotice from '../Cookies/CookiesNotice';

const Footer = () => {
  const siteData = useStaticQuery(graphql`
    {
      storyblokEntry(slug: { eq: "settings" }) {
        content
      }
    }
  `);
  let siteDoc = JSON.parse(siteData.storyblokEntry.content);

  return (
    <>
      <footer className="w-100 p-5 brand-bg-black brand-text-white small">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-5 text-center text-lg-left">
              <Richtext content={siteDoc.copyright} />
            </div>
            <div className="col-12 col-lg-7 text-center text-lg-right">
              <Richtext content={siteDoc.footer} />
            </div>
          </div>
        </div>
      </footer>
      <CookiesNotice message={siteDoc.cookies} />
    </>
  );
};

export default Footer;
