import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import SEO from '../SEO/SEO';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// ====

const Layout = (props) => {
  const { children, meta } = props;

  return (
    <>
      <SEO page={{ meta }} />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;

// ====

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
